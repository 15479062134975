//Dependencies
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Chakra
import {
  Button,
  Box,
} from '@chakra-ui/react';

//Providers
import { useChart } from '../providers/chart';


export default function DownloadBlobButton(props) {
  const { blob, filename } = props;
  const { isSingleView } = useChart();

  const handleOnClick = () => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const styles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    h: 10,
    px: isSingleView ? 4 : 1,
    py: isSingleView ? 4 : 1,
    bgColor: isSingleView ? 'primary.200' : 'transparent',
    color: 'blackAlpha.900',
    borderRadius: isSingleView ? 4 : 'sm',
    fontWeight: '500',
    lineHeight: '1',
    '&:hover': {
      bgColor: 'gray.200',
      textDecoration: 'none'
    }
  }

  return (
    <Button onClick={handleOnClick} title="Download chart SVG file." sx={styles}>
      <FontAwesomeIcon icon="fa-solid fa-download"  size={'lg'} /><Box as="span" sx={{display: isSingleView ? 'inline-block' : 'none'}}>&nbsp;Download</Box>
    </Button>
  )
}