// Dependencies
import { useState, useEffect } from 'react';

// Chakra
import { 
  Flex,
  Text,
  Switch
} from '@chakra-ui/react';

// Providers
import { useView } from '../providers/view';
import { useUser } from '../providers/user';


export default function TargetsToggle(props) {
  const {updateFBUserPref} = useUser();
  const {hasFullScreenTargets, setHasFullScreenTargets, } = useView();
  const [showTargets, setShowTargets] = useState(hasFullScreenTargets);

  const handleChange = (event) => {
    setShowTargets(event.target.checked);
    updateFBUserPref.fullScreenTargets(event.target.checked);
  }

  useEffect(() => {
    setHasFullScreenTargets(showTargets);
  }, [showTargets, setHasFullScreenTargets]);

  return (
    <Flex className="targets-toggle" gap={'xs'} alignItems={'center'} {...props}>
      <Text fontWeight={'700'} fontSize={'sm'}>Display Wave Targets</Text> <Switch defaultChecked={showTargets} checked={showTargets} colorScheme='green' onChange={handleChange}/>
    </Flex>
  );
}