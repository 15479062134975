//Dependencies
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import packageJSON from '../../../package.json';

//Chakra
import {
  Box,
  Stack,
  Flex,
  Text,
  Button,
  IconButton,
  useBreakpoint
} from '@chakra-ui/react';
import { ewavesConfig } from '../../config/theme-config';

//Providers
import { useAuthentication } from "../../providers/authentication";
import { useUser } from "../../providers/user";
import { useMarkets } from "../../providers/markets";

//Components
import {
  Search,
  Logo
} from '../../components';


const HeaderSearch = () => {
  const breakpoint = useBreakpoint({ ssr: false });

  return (
    <>
    {breakpoint === 'base'
      ?
        <Search w='200px' />
      :
        <Search w='300px' />
    }
    </>
  )
}

export default function Header() {
  const {showSearch} = useMarkets();
  const {authentication, setIsLoading, logout} = useAuthentication();
  const {user} = useUser();
  const [name, setName] = useState('Loading...');
  const breakpoint = useBreakpoint({ ssr: false });


  const handleLogout = () => {
    setIsLoading(true);
    logout();
  }

  useEffect(() => {
    if(authentication && user) {
      if(user.name) {
        setName(user.name);
      } else {
        setName(user.email);
      }
    }
  }, [user, authentication]);

  return (
    <Stack className={'header'} direction={'row'} spacing={0} px={'sm'} h={{base: ewavesConfig.headerHeightSmall+'px', desktop: ewavesConfig.headerHeightLarge+'px'}} justifyContent='space-between' alignItems='center' bgColor='bgPrimary' borderBottom='2px' borderBottomColor='bgSecondary'>
      <Flex direction={'row'} alignItems={'baseline'}>
        <Link to='/' className='logo'><Logo className='logo__image' d={'inline-flex'} w={{base: '100px', desktop: '150px'}} pointerEvents='none' /></Link>
        {/* {process.env.REACT_APP_FIREBASE_PROJECT_ID === "ewaves-live-dev" &&
          <Text ml={'xs'} fontSize={'xs'} lineHeight={'1'} display={{base: 'none', desktop:'block'}}>{packageJSON.version}</Text>
        } */}
      </Flex>
      { showSearch && <Box marginInline={'xs'}><HeaderSearch /></Box> }
      <Flex direction={'row'} alignItems={'center'}>
        { name && (
          <>
            {breakpoint === 'base'
              ?
                <IconButton aria-label='Logout' variant={'ghost'} icon={<FontAwesomeIcon icon="fa-solid fa-right-from-bracket" />} />
              :
              <>
                <Text>Welcome, {name}</Text>
                <Button ml={'xs'} size={'sm'} variant={'link'} onClick={handleLogout}>(Logout)</Button>
              </>
            }
          </>
          )
        }
        {/* <ColorModeSwitcher /> */}
      </Flex>
    </Stack>
  );
}